define("ember-intercom-io/initializers/ember-intercom", ["exports", "intercom"], function (_exports, _intercom) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize(application) {
    if (typeof FastBoot === 'undefined') {
      var config;

      if (application.resolveRegistration) {
        config = application.resolveRegistration('config:environment');
      } else {
        config = application.registry.resolve('config:environment');
      }

      (0, _intercom._setup)(config);
    }
  }

  var _default = {
    name: 'ember-intercom',
    initialize: initialize
  };
  _exports.default = _default;
});