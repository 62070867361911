define("ember-validated-form/components/validated-label", ["exports", "ember-validated-form/templates/components/validated-label"], function (exports, _validatedLabel) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _validatedLabel.default,

    tagName: "label",

    attributeBindings: ["inputId:for"],

    classNameBindings: ["config.css.label"],

    requiredLabel: Ember.computed("config", function () {
      return this.get("config.label.required") || "*";
    })
  });
});