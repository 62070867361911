define("ember-power-select-typeahead/components/power-select-typeahead", ["exports", "ember-power-select-typeahead/templates/components/power-select-typeahead"], function (_exports, _powerSelectTypeahead) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    layout: _powerSelectTypeahead.default,
    tabindex: -1,
    triggerComponent: 'power-select-typeahead/trigger',
    beforeOptionsComponent: null,
    loadingMessage: null,
    noMatchesMessage: null,
    onkeydown: function onkeydown() {},
    // CPs
    concatenatedTriggerClasses: Ember.computed('triggerClass', function () {
      var classes = ['ember-power-select-typeahead-trigger'];
      var passedClass = this.get('triggerClass');

      if (passedClass) {
        classes.push(passedClass);
      }

      return classes.join(' ');
    }),
    concatenatedDropdownClasses: Ember.computed('dropdownClass', function () {
      var classes = ['ember-power-select-typeahead-dropdown'];
      var passedClass = this.get('dropdownClass');

      if (passedClass) {
        classes.push(passedClass);
      }

      return classes.join(' ');
    }),
    actions: {
      onKeyDown: function onKeyDown(select, e) {
        var action = this.get('onkeydown'); // if user passes `onkeydown` action

        if (action && action(select, e) === false) {
          return false;
        } else {
          // if escape, then clear out selection
          if (e.keyCode === 27) {
            select.actions.choose(null);
          }
        }
      }
    }
  });

  _exports.default = _default;
});