define("ember-math-helpers/helpers/lcm", ["exports", "ember-math-helpers/helpers/gcd"], function (_exports, _gcd) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.lcm = lcm;
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

  function _iterableToArrayLimit(arr, i) { if (!(Symbol.iterator in Object(arr) || Object.prototype.toString.call(arr) === "[object Arguments]")) { return; } var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  /**
   * Returns the smallest positive integer that is divisible by both number1 and number2
   *
   * ```hbs
   * {{lcm a b}}
   * ```
   *
   * @param {number} number1 The first number
   * @param {number} number2 The second number
   * @return {number} The LCM of the two numbers passed
   */
  function lcm(_ref) {
    var _ref2 = _slicedToArray(_ref, 2),
        _ref2$ = _ref2[0],
        number1 = _ref2$ === void 0 ? 0 : _ref2$,
        _ref2$2 = _ref2[1],
        number2 = _ref2$2 === void 0 ? 0 : _ref2$2;

    return number1 === 0 || number2 === 0 ? 0 : Math.abs(number1 * number2) / (0, _gcd.gcd)([number1, number2]);
  }

  var _default = Ember.Helper.helper(lcm);

  _exports.default = _default;
});