define("ember-infinity/lib/infinity-promise-array", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.ArrayProxy.extend(Ember.PromiseProxyMixin);

  _exports.default = _default;
});