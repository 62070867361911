define("ember-validated-form/components/validated-form", ["exports", "ember-validated-form/templates/components/validated-form"], function (exports, _validatedForm) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var PROP_ON_SUBMIT = "on-submit";
  var PROP_ON_INVALID_SUBMIT = "on-invalid-submit";

  exports.default = Ember.Component.extend({
    tagName: "form",

    classNameBindings: ["_cssClass", "submitted"],
    attributeBindings: ["autocomplete"],

    loading: false,

    submitted: false,

    layout: _validatedForm.default,

    validateBeforeSubmit: true,

    init: function init() {
      this._super.apply(this, arguments);
      if (this.get("model") && this.get("model").validate) {
        this.get("model").validate();
      }

      var owner = Ember.getOwner(this);
      var factory = owner.factoryFor ? owner.factoryFor("service:i18n") : owner._lookupFactory("service:i18n");
      this.set("i18n", factory ? factory.create() : null);
    },


    _cssClass: Ember.computed("config", function () {
      return this.get("config.css.form");
    }),

    _submitLabel: Ember.computed("config", "submit-label", function () {
      return this._getLabel("submit") || "Save";
    }),

    _getLabel: function _getLabel(type) {
      var i18n = this.get("i18n");
      var label = this._config(type);
      return i18n ? i18n.t(label) : label;
    },
    _config: function _config(type) {
      return this.get("config.label." + type);
    },


    submitClass: Ember.computed("config", function () {
      return this.get("config.css.submit") || this.get("config.css.button");
    }),

    submit: function submit() {
      var _this = this;

      this.set("submitted", true);
      var model = this.get("model");

      if (!model || !model.validate) {
        this.runCallback(PROP_ON_SUBMIT);
        return false;
      }

      model.validate().then(function () {
        if (!_this.element) {
          // We were removed from the DOM while validating
          return;
        }

        if (model.get("isInvalid")) {
          _this.runCallback(PROP_ON_INVALID_SUBMIT);
        } else {
          _this.runCallback(PROP_ON_SUBMIT);
        }
      });
      return false;
    },
    runCallback: function runCallback(callbackProp) {
      var _this2 = this;

      var callback = this.get(callbackProp);
      if (typeof callback !== "function") {
        return;
      }
      var model = this.get("model");

      this.set("loading", true);
      Ember.RSVP.resolve(callback(model)).finally(function () {
        if (!_this2.element) {
          // We were removed from the DOM while running on-submit()
          return;
        }
        _this2.set("loading", false);
      });
    }
  });
});