define("ember-intercom-io/mixins/intercom-route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    intercom: Ember.inject.service(),

    /**
     * push page changes to intercom
     * @private
     * @on didTransition
     */
    submitRouteChange: Ember.on('didTransition', function () {
      var intercom = Ember.get(this, 'intercom');
      (false && !(intercom) && Ember.assert('Could not find property configured intercom instance', intercom));

      if (Ember.get(intercom, 'isBooted')) {
        intercom.update();
      }
    })
  });

  _exports.default = _default;
});