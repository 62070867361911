define("ember-intercom-io/components/intercom-io", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intercom: Ember.inject.service(),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      Ember.get(this, 'intercom').start();
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      Ember.get(this, 'intercom').stop();
    }
  });

  _exports.default = _default;
});